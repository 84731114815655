<template>
  <div>
    <div class="px-4 pt-4 w-100 d-flex align-center justify-space-between">
      <v-form ref="form" v-model="formValid" lazy-validation @submit.prevent="page=1;paginationCount=2;getLogs()">
        <div class="d-flex flex-wrap align-center">
          <div class="px-2 fsize14">
            <div class="pb-1">User ID</div>
            <v-text-field outlined dense placeholder="Enter the UserId" v-model="userId"></v-text-field>
          </div>
          <!-- <div class="px-2 fsize14">
            <div class="pb-1">*Log Type</div>
            <v-autocomplete outlined dense @change="isSubmit = false;page=1" :items="['Codifi Logs','Odin Logs']" placeholder="Select the LogType" :menu-props="{ bottom: true, offsetY: true }" :rules="logTypeRules" v-model="logType"></v-autocomplete>
          </div> -->
          <div class="px-2 fsize14">
            <div class="pb-1">URI</div>
            <v-text-field outlined dense placeholder="Enter the URI" v-model="uri"></v-text-field>
          </div>

          <div class="px-2 fsize14">
            <v-menu v-model="dateMenu" :close-on-content-click="false" transition="scale-transition" offset-y
              max-width="290px" min-width="auto">
              <template v-slot:activator="{ on, attrs }">
                <div class="pb-1">*Date</div>
                <v-text-field @click:append="dateMenu = true" v-model="computedDateFormatted" outlined persistent-hint
                  :menu-props="{ bottom: true, offsetY: true }" :rules="dateRules" placeholder="Select the Date"
                  append-icon="mdi-calendar" readonly dense v-bind="attrs" v-on="on"></v-text-field>
              </template>
              <v-date-picker v-model="date"
                :min="new Date(Date.now() - 6 * 24 * 60 * 60 * 1000).toISOString().substr(0, 10)"
                :max="new Date().toISOString().substr(0, 10)" :show-current="true" no-title
                @input="dateMenu = false"></v-date-picker>
            </v-menu>
          </div>
          <div>
            <div class="pb-1">*Time</div>
            <input type="time" class="time-picker" v-model="time">
          </div>
          <div class="px-2 d-flex justify-end">
            <v-btn height="40" type="submit" color="primary" depressed class="text-capitalize fsize14">Submit</v-btn>
          </div>
          <div class="loaderPart d-flex align-center">
            <v-progress-circular class="ml-2" indeterminate v-if="getLogsLoader" size="18" :width="2"
              color="black"></v-progress-circular>
          </div>
        </div>
      </v-form>
    </div>
    <!-- accesslog with input -->
    <v-simple-table class="my-4 border-top" v-if="!getLogsLoader && getKambalaLogs.length != 0">
      <thead>
        <tr>
          <th class="text-center nowrap">Created Date</th>
          <th class="text-center">userId</th>
          <th class="text-center">DeviceIp</th>
          <th class="text-center">Path</th>
          <th class="text-center">User Agent</th>
          <th class="text-center">Input</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(i, idx) in getKambalaLogs" :key="idx" >
          <td class="text-center" style="min-width:200px">{{ i.created_on }}</td>
          <td class="text-center">{{ i.user_id }}</td>
          <td class="text-center">{{ i.deviceIp }}</td>
          <td class="text-center">{{ i.uri }}</td>
          <td class="input_text1 text-center">
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <span v-bind="attrs" v-on="on">
                  {{ i.userAgent }}
                </span>
              </template>
              <span class="max-width-250px">{{i.userAgent}}</span>
            </v-tooltip>
          </td>
          <td class="input_text text-center" @click="callDialog(i.req_body ? i.req_body : i.res_body)">{{ i.req_body ?
            i.req_body : i.res_body }}</td>
        </tr>
      </tbody>
    </v-simple-table>

    <div v-else-if="!getLogsLoader" class="d-flex justify-center pa-5 py-8">
        No Records Found
    </div>


    <!-- v-if="isSubmit && accessLogs.length != 0"  -->
    <div class="d-flex align-center justify-end pa-4" v-if="getKambalaLogs.length != 0">
      <v-pagination v-model="page" :length="paginationCount" :total-visible="7"></v-pagination>
    </div>

    <v-dialog class="json-dialog" v-model="dialog" max-width="600" style="overflow-x:hidden">
      <v-card>
        <div class="d-flex dialog-header" style="justify-content: space-between;border-bottom:-16px solid black">
          <div class="font-weight-bold fsize14 mx-6">Response</div>
          <div>
            <v-btn height="28" depressed outlined text class="text-capitalize fsize12" @click="formatFlag?format():''">Format</v-btn>
            <v-btn height="28" depressed outlined text class="text-capitalize fsize12 mx-4" :color="copyText == 'Copy' ? 'black' : 'green'" @click="copyTextFunc()">{{copyText}}</v-btn>
            <v-icon @click="dialog = false" color="primary">mdi-close-circle</v-icon>
          </div>
        </div>
        <div v-if="formatFlag" class="fsize14 line-height-2 pa-4" id="jsonData" ref="jsonData">{{ currentJson }}</div>
        <div v-else>
          <json-viewer :value="currentJson" sort></json-viewer>
        </div>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
// Import JsonViewer as a Vue.js plugin
import Vue from "vue";
import JsonViewer from "vue-json-viewer";
Vue.use(JsonViewer);

export default {
  data() {
    return {
      dialog: false,
      isSubmit: false,
      userId: '',
      page: 1,
      dateMenu: false,
      date: new Date().toISOString().substr(0, 10),
      logType: 'Codifi Logs',
      time: "09:30",
      formValid: false,
      dateRules: [(v) => !!v || "Date is required"],
      logTypeRules: [(v) => !!v || "Log type is required"],
      loader: false,
      uri: '',
      paginationCount: 2,
      copyText: "copy",
      currentJson: [],
      formatFlag: true,
    }
  },
  computed: {
    ...mapGetters('logs', ['getKambalaLogs','getLogsLoader']),
    computedDateFormatted() {
      return this.formatDate(this.date);
    },
  },
  methods: {
    copyTextFunc() {
      navigator.clipboard.writeText(JSON.stringify(this.currentJson));
      this.copyText = "Copied";
      setTimeout(() => {
        this.copyText = "Copy";
      }, 2000);
    },

    getLogs() {
      if (this.$refs.form.validate()) {
        let timeInHours = this.time.split(':')[0]
        let json = {
          "user_id": this.userId,
          "uri": this.uri,
          "date": this.date,
          "hours": parseInt(timeInHours),
          "pageNumber": this.page,
          "pageSize": "50"
        }
        this.$store.dispatch('logs/getKambalaLogs', json)
      }
    },

    formatDate(date) {
      if (!date) return null;
      const [year, month, day] = date.split("-");
      return `${day}/${month}/${year}`;
    },

    callDialog(data) {
      this.copyText = "Copy";
      data && data != `""` ? (this.dialog = true) : "";
      this.currentJson = data;
    },
    
    format() {
      this.formatFlag ? (this.currentJson = JSON.parse(this.currentJson)) : "";
      this.formatFlag = !this.formatFlag;
    },
  },
  watch: {
    page(val) {
      if (Number(val) == Number(this.paginationCount)) {
        this.paginationCount = this.paginationCount + 1
      }
      this.getLogs()
    },
    dialog(val) {
      this.formatFlag = true;
    },
  },
  beforeDestroy() {
    this.$store.commit('logs/SET_KAMBALA_LOGS', [])
  }
}
</script>